import React, { useEffect, useRef, useState } from "react"
import * as styles from './betriebsmarkt.module.scss';

import IframeResizer from 'iframe-resizer-react';
import Layout from "../page-components/layout"
import RcDefaultPageTeaser from "../page-components/teaser/rcDefaultPageTeaser"
import { graphql } from "gatsby"
import BetriebsmarktContactSuche from "../pages-parts/betriebsmarkt/betriebsmarkt-contact-suche"
import BetriebsmarktContactOffer from "../pages-parts/betriebsmarkt/betriebsmarkt-contact-offer"

const BetriebsmarktPage = ( { data } ) => {

  const [ state, setState ] = useState('angebote');

  const [ offersResized, setOffersResized ] = useState(0);
  const [ searchingResized, setSearchingResized ] = useState(0);
  const [ timeoutReached, setTimeoutReached ] = useState(false);

  const refOffers = useRef(null);
  const refSearchings = useRef(null);

  useEffect(
    () => {
      window.setTimeout(
        () => setTimeoutReached(true),
        3500
      );
    },
    []
  );

  useEffect(
    () => {
      const urlParams = new URLSearchParams(window.location.search);

      if (urlParams.has('searching')) {
        setState('suchprofile');
      }
    },
    []
  )

  useEffect(
    () => {
      if (timeoutReached) return;
      const urlParams = new URLSearchParams(window.location.search);

      if (urlParams.has('offer')) {
        refOffers.current?.sendMessage({ target: 'offers', id: urlParams.get('offer') });
      }
    },
    [ offersResized ]
  );

  useEffect(
  () => {
        if (timeoutReached) return;
        const urlParams = new URLSearchParams(window.location.search);

        if (urlParams.has('searching')) {
          refSearchings.current?.sendMessage({ target: 'searching', id: urlParams.get('searching') });
        }
     },
    [ searchingResized ]
  );

  return <Layout>

    <RcDefaultPageTeaser>
      <h1>Betriebsmarkt</h1>

      <div dangerouslySetInnerHTML={
        {
          __html: data.wp.texteFRSektionen.acf.introbetriebsmarkt
        }
      } />

    </RcDefaultPageTeaser>

    <div className="container bg-white h-100">

      <div className="row">
        <div className="d-flex w-100 justify-content-between">
            <p className={styles.tabButton + " " + (state === 'angebote' ? styles.active : "") }
              onClick={ (e) => { setState('angebote'); } }>
              Betriebsangebote
            </p>

            <p className={styles.tabButton + " " + (state === 'suchprofile' ? styles.active : "") }
              onClick={ (e) => setState('suchprofile')}>
              Suchprofile
            </p>
        </div>
      </div>



      {state === 'angebote' &&
      <div className={"row " + styles.iframeWrapper}>
        <IframeResizer
          forwardRef={refOffers}
          src={"https://iframe.betrieb-zu-haben.at/2a44336d-73eb-11ea-93cb-901b0efba6c7"}
          onResized={ () => { setOffersResized(s => s + 1); } }
        />


        <BetriebsmarktContactSuche />
      </div>
      }

      {state === 'suchprofile' &&
      <div className={"row mt-4 " + styles.iframeWrapper}>
        <IframeResizer
          forwardRef={refSearchings}
          src={"https://iframe.betrieb-zu-haben.at/95574deb-7619-11eb-8066-901b0efba6c7"}
          onResized={ () => setSearchingResized(s => s + 1) }
        />

        <BetriebsmarktContactOffer />
      </div>
      }

    </div>

  </Layout>

};

export default BetriebsmarktPage;

export const query = graphql`query BetriebsmarktIndexPage {
  wp {
    texteFRSektionen {
        acf {
          introbetriebsmarkt
        }
    }
  }
}`;
